import { Component } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

import HotNews from "./News__Hot";
import LatestNews from "./News__Latest";
import "./style.css";

class News extends Component {
  constructor() {
    super();
    this.hotNews = [
      {
        title: "Innovative IT Solutions for Business Growth",
        avatar:
          "https://www.iconfinder.com/data/icons/avatars-xmas-giveaway/128/engineer_it_computer_developer-512.png",
        text:
          "Our team delivers cutting-edge technology solutions that drive business growth, increase efficiency, and solve complex IT challenges.",
        author: "Alex Johnson",
        role: "Lead Developer"
      },
      {
        title: "Seamless Mobile App Development",
        avatar:
          "https://www.iconfinder.com/data/icons/avatars-xmas-giveaway/128/programmer_coder_developer_avatar-512.png",
        text:
          "We specialize in building mobile applications that offer great user experience, scalability, and innovative functionality for all platforms.",
        author: "Emma White",
        role: "Mobile App Specialist"
      }
    ];

    this.latestNews = [
      {
        img:
          "https://images.pexels.com/photos/1779487/pexels-photo-1779487.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        date: "01 November 2024",
        author: "Admin",
        text:
          "Explore the latest trends in web development for 2024, from AI integrations to enhanced user personalization and interactive designs."
      },
      {
        img:
          "https://images.pexels.com/photos/1181263/pexels-photo-1181263.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        date: "15 October 2024",
        author: "Admin",
        text:
          "How cybersecurity plays a crucial role in modern app development and tips to safeguard user data with advanced security protocols."
      }
    ];
  }

  render() {
    return (
      <section id="news" className="container news__container">
        <Swiper className="news__hot" pagination={true} modules={[Pagination]}>
          {this.hotNews.map((feed, index) => {
            return (
              <SwiperSlide key={index}>
                <HotNews
                  title={feed.title}
                  text={feed.text}
                  avatar={feed.avatar}
                  author={feed.author}
                  role={feed.role}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className="news__latest">
          <h1>Get The Latest News Here</h1>
          {this.latestNews.map((feed, index) => {
            return (
              <LatestNews
                img={feed.img}
                date={feed.date}
                author={feed.author}
                text={feed.text}
                key={index}
              />
            );
          })}
        </div>
      </section>
    );
  }
}

export default News;

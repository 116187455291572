import { Component } from "react";
import { CgInstagram } from "react-icons/cg";
import { AiFillTwitterSquare } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import LogoPic from '../../assets/logo.png'

import "./style.css";
class Footer extends Component {
  render() {
    return (
      <footer id="contact" className="container footer__container">
        <div className="about">
        <div className="logo">
        <img src={LogoPic} alt="logo" />
      </div>
          <p className="text">
          CodSplit is a full-service digital media marketing agency. Unlock your potential and elevate your brand with cutting-edge digital technologies and marketing solutions.
          </p>
          <ul className="footer__socials">
            <li>
              <a href="#">
                <AiFillFacebook />
              </a>
            </li>
            <li>
              <a href="#">
                <CgInstagram />
              </a>
            </li>
            <li>
              <a href="#">
                <AiFillTwitterSquare />
              </a>
            </li>
          </ul>
        </div>
        <div className="services">
          <h3>Services</h3>
          <ul>
  <li>
    <a href="#" className="text">
      Web Development
    </a>
  </li>
  <li>
    <a href="#" className="text">
      Mobile Development
    </a>
  </li>
  <li>
    <a href="#" className="text">
      Social Media Management
    </a>
  </li>
  <li>
    <a href="#" className="text">
      SEO Optimization
    </a>
  </li>
</ul>

        </div>
        <div className="contact">
          <h3>Contact</h3>
          <ul>
            <li>
              <address className="text">
              3509 N Belt Line Rd, Irving, TX 75062, USA
              </address>
            </li>
            <li>
              <a href="tel:+923035100225" className="text">
                Tel : +923035100225
              </a>
            </li>
            <li>
              <a href="mailto:info@codsplit.com" className="text">
                Email: info@codsplit.com
              </a>
            </li>
          </ul>
        </div>
      </footer>
    );
  }
}

export default Footer;

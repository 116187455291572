import { Component } from "react";
import { GrShieldSecurity } from "react-icons/gr";
import { BsLaptop, BsPhone } from "react-icons/bs";
import { FaSearch, FaMobileAlt, FaCloud } from "react-icons/fa";

class ServicesComp extends Component {
  render() {
    return (
      <div className="services__servicesComp">
        <div>
          <h1>Our Servcices For Technology You Need</h1>
          <button className="btn btn-primary">Load More</button>
        </div>
        <div className="services__container">
      <div>
        <div className="icon">
          <BsLaptop />
        </div>
        <h4>Web Development</h4>
        <p>Custom, responsive websites built to engage and convert.</p>
      </div>
      <div>
        <div className="icon">
          <FaMobileAlt />
        </div>
        <h4>Mobile App Development</h4>
        <p>Innovative mobile apps for iOS and Android platforms.</p>
      </div>
      <div>
        <div className="icon">
          <FaSearch />
        </div>
        <h4>SEO Optimization</h4>
        <p>Boost your online visibility with tailored SEO strategies.</p>
      </div>
  
     
      <div>
        <div className="icon">
          <BsLaptop />
        </div>
        <h4>IT Consulting</h4>
        <p>Expert guidance to streamline and optimize your IT strategy.</p>
      </div>
    </div>
      </div>
    );
  }
}

export default ServicesComp;

import { Component } from "react";

class Testimony extends Component {
  constructor(props) {
    super();
  }
  render() {
    return (
      <div className="testimony">
        <div className="testimony__avatar">
          <img src="https://via.placeholder.com/150" alt="" />
        </div>
        <div className="testimony__comment">
          <p className="text">
          {this.props.title}
          </p>
         
        </div>
      </div>
    );
  }
}
export default Testimony;

import { Component } from "react";
import Button from "../Global__Components/Button";

class Quote extends Component {
  handleWhatsAppClick = () => {
    const phoneNumber = "+923035100225";
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };

  render() {
    return <Button content="Get a Quote" onClick={this.handleWhatsAppClick} />;
  }
}

export default Quote;

import { Component } from "react";
import "./style.css";

class Banner extends Component {
  handleGetInTouchClick = () => {
    const phoneNumber = "+923035100225";
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };

  render() {
    return (
      <section id="banner" className="banner__container">
        <div className="container">
          <h1>
            Let's Boost Your Business
            <br /> With The Latest Technology
          </h1>
          <button
            className="btn btn-primary"
            onClick={this.handleGetInTouchClick}
          >
            Get In Touch
          </button>
        </div>
      </section>
    );
  }
}

export default Banner;
